import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { RemoteFileInfoRepoImpl } from "domain/repository/Common/RemoteFileInfoRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { FinancialReportRepoImpl } from "domain/repository/FinancialReport/FinancialReportRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { MonthEndRepoImpl } from "domain/repository/MonthEnd/MonthEndRepoImpl";
import { useFinancialReportTracked } from "presentation/store/FinancialReport/FinancialReportProvider";
import { FinancialReportVM } from "presentation/viewModel/FinancialReport/FinancialReportVM";
import { useMemo } from "react";

export const useFinancialReportVM = () => {
    const [, setFinancialReportState] = useFinancialReportTracked();
    const financialReportVM = useMemo(() =>
        FinancialReportVM({
            dispatch: [setFinancialReportState],
            financialReportRepo: FinancialReportRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl(),
            remoteFileInfoRepo: RemoteFileInfoRepoImpl(),
            monthEndRepo: MonthEndRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setFinancialReportState])

    return financialReportVM
}