export interface FinancialReportEntity {

    reportType: string,
    printer: string,
    fileLocation: string,
    exportType?: string | null,
    soa?: string | null,
    vslOperationDateFrom?: Date | null,
    vslOperationDateTo?: Date | null,
    operationDateFrom?: Date | null,
    operationDateTo?: Date | null,
    p1OperationDateFrom?: Date | null,
    p1OperationDateTo?: Date | null,
    p2OperationDateFrom?: Date | null,
    p2OperationDateTo?: Date | null,
    etdFrom?: Date | null,
    etdTo?: Date | null,
    docDateFrom?: Date | null,
    docDateTo?: Date | null,
    effectiveDateFrom?: Date | null,
    effectiveDateTo?: Date | null,
    chargeOnCompany?: string | null,
    billToCompany?: string | null,
    glDateFrom?: Date | null,
    glDateTo?: Date | null,
    year?: string | null,
    month?: string | null,

    [key: string]: string | boolean | Date | number | null | Object | undefined
}

export const EMPTY_FINANCIAL_REPORT_ENTITY: FinancialReportEntity = {
    reportType: "",
    printer: "",
    fileLocation: "",
    exportType: null,
    soa: null,
    vslOperationDateFrom: null,
    vslOperationDateTo: null,
    operationDateFrom: null,
    operationDateTo: null,
    p1OperationDateFrom: null,
    p1OperationDateTo: null,
    p2OperationDateFrom: null,
    p2OperationDateTo: null,
    etdFrom: null,
    etdTo: null,
    docDateFrom: null,
    docDateTo: null,
    effectiveDateFrom: null,
    effectiveDateTo: null,
    chargeOnCompany: null,
    billToCompany: null,
    glDateFrom: null,
    glDateTo: null,
    year: null,
    month: null,

}
