import { DEFAULT_ENABLED_FINANCIAL_REPORT_FIELD, FinancialReportEnabledField } from "domain/entity/FinancialReport/FinancialReportEnabledField";
import { EMPTY_FINANCIAL_REPORT_ENTITY, FinancialReportEntity } from "domain/entity/FinancialReport/FinancialReportEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface FinancialReportDropdownOptions {
    reportTypeDropdownOptions: DropdownProps[],
    printQueueDropdownOptions: DropdownProps[],
    fileLocationDropdownOptions: DropdownProps[],
    soaDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    yearDropdownOptions: DropdownProps[],
    monthDropdownOptions: DropdownProps[],
}
export interface FinancialReportViewChangeState extends BaseViewChangeSate {
}

export interface FinancialReportModel {
    isLoading: boolean,
    reportIdList: string[],
    reportTypeList: string[],
    printQueueIdMap: { [key: string]: number },
    reportIdTypeMap: { [key: string]: string },
    criteriaTypeIdMap: { [key: string]: string },
    dynamicOptions: FinancialReportDropdownOptions,
    currentReport: FinancialReportEntity,
    enabledField: FinancialReportEnabledField,

}

export const EMPTY_FINANCIAL_REPORT_MODEL: FinancialReportModel = {
    isLoading: false,
    dynamicOptions: {
        reportTypeDropdownOptions: [],
        printQueueDropdownOptions: [],
        fileLocationDropdownOptions: [],
        soaDropdownOptions: [],
        companyCodeDropdownOptions: [],
        yearDropdownOptions: [],
        monthDropdownOptions: []
    },
    printQueueIdMap: {},
    reportTypeList: [],
    currentReport: { ...EMPTY_FINANCIAL_REPORT_ENTITY },
    enabledField: { ...DEFAULT_ENABLED_FINANCIAL_REPORT_FIELD },
    reportIdTypeMap: {},
    reportIdList: [],
    criteriaTypeIdMap: {}
}