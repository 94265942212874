import { useFinancialReportVM } from "presentation/hook/FinancialReport/useFinancialReportVM";
import { memo, useEffect, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import FinancialReportPanel from "./FinancialReportPanel";


const FinancialReportMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const financialReportVM = useFinancialReportVM();


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    financialReportVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            financialReportVM.initFinancialReportList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [financialReportVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>

            {<>
                <FinancialReportPanel /></>}

        </div>
    </>
}

export default memo(FinancialReportMaintenance);