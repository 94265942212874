import { FinancialReportEntity } from "domain/entity/FinancialReport/FinancialReportEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { RemoteFileInfoRepository } from "domain/repository/Common/RemoteFileInfoRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { FinancialReportRepository } from "domain/repository/FinancialReport/FinancialReportRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { MonthEndRepository } from "domain/repository/MonthEnd/MonthEndRepo";
import _ from "lodash";
import { FinancialReportModel } from "presentation/model/FinancialReport/FinancialReportModel";
import { financialReportEnabledField } from "presentation/utils/report/ReportEnabledFieldUtils";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface FinancialReportVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<FinancialReportModel>> | ((value: SetStateAction<FinancialReportModel>) => void),
    ],
    financialReportRepo: FinancialReportRepository,
    printQueueRepo: PrintQueueRepository,
    remoteFileInfoRepo: RemoteFileInfoRepository,
    companyRepo: CompanyRepository,
    monthEndRepo: MonthEndRepository,
    masterDataRepo: MasterDataRepository,
}

export const FinancialReportVM = ({ dispatch, financialReportRepo, printQueueRepo, remoteFileInfoRepo, monthEndRepo,
    companyRepo, masterDataRepo }: FinancialReportVMProps) => {
    const [financialReportDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await masterDataRepo.getMasterDataByKey(MasterDataType.SOA).then(
            soas => {
                const soaCodeDropdownOptions = soas?.map((soa) => ({
                    dropdownLabel: soa.code,
                    tagLabel: soa.code,
                    value: soa.code,
                })) ?? []

                financialReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        soaDropdownOptions: soaCodeDropdownOptions
                    }
                }))
            }
        );


        await printQueueRepo.getAllPrintQueues().then(
            datas => {
                let printQueues = datas.filter(entity => entity.activeInd === 'Y');

                const dataObj = printQueues.reduce((acc, item) => {
                    const key = item.id;
                    const value = item.printQueueName;
                    acc[value] = key;
                    return acc;
                }, {} as { [key: string]: number });


                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                financialReportDispatch(prevState => ({
                    ...prevState,
                    printQueueIdMap: dataObj,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printQueueDropdownOptions: printQueueDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        printer: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )

        await remoteFileInfoRepo.getFileLoc().then(
            fileLocs => {
                let fileLocationDropdownOptions = fileLocs?.map((fileLoc) => ({
                    dropdownLabel: fileLoc,
                    tagLabel: fileLoc,
                    value: fileLoc,
                })) ?? []
                fileLocationDropdownOptions = _.orderBy(fileLocationDropdownOptions, "dropdownLabel");

                financialReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fileLocationDropdownOptions: fileLocationDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        fileLocation: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                    },
                    enabledField: {
                        ...prevState.enabledField,
                        companyCode: false,
                        billingType: false,
                        docNoFrom: true,
                        docNoTo: true,
                        docDateFrom: true,
                        docDateTo: true,
                        transactionDateFrom: false,
                        transactionDateTo: false,
                        proposalNo: false,
                        adjustmentDateFrom: false,
                        adjustmentDateTo: false
                    }
                }))
            }
        )

        await monthEndRepo.getAllMonthEndHdrYearForCombox().then(
            datas => {
                let yearDropdownOptions = datas?.map((year) => ({
                    dropdownLabel: year.toString(),
                    tagLabel: year.toString(),
                    value: year.toString(),
                })) ?? []
                yearDropdownOptions = _.orderBy(yearDropdownOptions, "dropdownLabel");

                financialReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        yearDropdownOptions: [...yearDropdownOptions],
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                financialReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: [...companyCodeDropdownOptions],
                        chargeOnCompanyDropdownOptions: [...companyCodeDropdownOptions],
                    }
                }))
            }
        )

    }

    const initFinancialReportList = async () => {
        await financialReportRepo.initFinancialReportList().then(data => {

            const [reportIds, reportNames] = data.reduce<[string[], string[]]>((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[0].push(key);
                acc[1].push(value.trim());
                return acc;
            }, [[], []] as [string[], string[]]);

            const dataObj = data.reduce((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[value] = key.trim();
                return acc;
            }, {} as { [key: string]: string });

            const reportTypeDropdownOption = reportNames?.map((report) => ({
                dropdownLabel: report,
                tagLabel: report,
                value: report,
            })) ?? []
            financialReportDispatch(prevState => {
                return {
                    ...prevState,
                    reportIdTypeMap: dataObj,
                    reportTypeList: reportNames,
                    reportIdList: reportIds,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reportTypeDropdownOptions: reportTypeDropdownOption
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        reportType: reportTypeDropdownOption.length > 0 ? reportTypeDropdownOption[0].value : '',
                        chargeOnCompany: prevState.dynamicOptions.companyCodeDropdownOptions[0].value,
                        year: prevState.dynamicOptions.yearDropdownOptions[0].value,
                    }
                }
            })
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        financialReportDispatch(prevState => {
            return {
                ...prevState,
                currentReport: {
                    ...prevState.currentReport,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onReportTypeChanged = async (reportType: string) => {
        const enabledField = financialReportEnabledField(reportType);
        financialReportDispatch(prevState => {
            return {
                ...prevState,
                enabledField: enabledField,
                currentReport: {
                    ...prevState.currentReport,
                    // reportType: prevState.dynamicOptions.reportTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.reportTypeDropdownOptions[0].value : '',
                    printer: prevState.dynamicOptions.printQueueDropdownOptions.length > 0 ? prevState.dynamicOptions.printQueueDropdownOptions[0].value : '',
                    fileLocation: prevState.dynamicOptions.fileLocationDropdownOptions.length > 0 ? prevState.dynamicOptions.fileLocationDropdownOptions[0].value : '',
                    exportType: null,
                    soa: '',
                    vslOperationDateFrom: null,
                    vslOperationDateTo: null,
                    operationDateFrom: null,
                    operationDateTo: null,
                    p1OperationDateFrom: null,
                    p1OperationDateTo: null,
                    p2OperationDateFrom: null,
                    p2OperationDateTo: null,
                    etdFrom: null,
                    etdTo: null,
                    docDateFrom: null,
                    docDateTo: null,
                    effectiveDateFrom: null,
                    effectiveDateTo: null,
                    chargeOnCompany: '',
                    billToCompany: '',
                    glDateFrom: null,
                    glDateTo: null,
                    year: '',
                    month: '',
                },
            }
        })
    }

    const onResetClick = async () => {
        financialReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                reportType: prevState.dynamicOptions.reportTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.reportTypeDropdownOptions[0].value : '',
                printer: prevState.dynamicOptions.printQueueDropdownOptions.length > 0 ? prevState.dynamicOptions.printQueueDropdownOptions[0].value : '',
                fileLocation: prevState.dynamicOptions.fileLocationDropdownOptions.length > 0 ? prevState.dynamicOptions.fileLocationDropdownOptions[0].value : '',
                exportType: null,
                soa: '',
                vslOperationDateFrom: null,
                vslOperationDateTo: null,
                operationDateFrom: null,
                operationDateTo: null,
                p1OperationDateFrom: null,
                p1OperationDateTo: null,
                p2OperationDateFrom: null,
                p2OperationDateTo: null,
                etdFrom: null,
                etdTo: null,
                docDateFrom: null,
                docDateTo: null,
                effectiveDateFrom: null,
                effectiveDateTo: null,
                chargeOnCompany: '',
                billToCompany: '',
                glDateFrom: null,
                glDateTo: null,
                year: '',
                month: '',
            },
        }))
    }

    const onPrintClick = async (reportIdTypeMap: { [key: string]: string }, entity: FinancialReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
        }
        return await financialReportRepo.print(data);
    }


    const onExportClick = async (reportIdTypeMap: { [key: string]: string }, entity: FinancialReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
        }
        return await financialReportRepo.exportReport(data);
    }

    const onExportAsClick = async (reportIdTypeMap: { [key: string]: string }, entity: FinancialReportEntity, exportType: string,) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
            exportType: exportType,
        }
        return await financialReportRepo.exportReport(data);
    }

    const onDownloadClick = async (reportIdTypeMap: { [key: string]: string }, entity: FinancialReportEntity, downloadType: string,) => {
        let data = { ...entity };
        data = {
            ...data,
            reportType: reportIdTypeMap[entity.reportType],
            exportType: downloadType,
        }
        return await financialReportRepo.downloadReport(data).then((data) => {
            if (data && data.success) {
                const res = data.data;
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        const blob = new Blob([u8arr], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        if (downloadType) {
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = key + "." + downloadType.toLowerCase();
                            a.style.display = 'none';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        }
                    }
                }
                return true;
            }
            return false;
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        financialReportDispatch(prevState => {
            return {
                ...prevState,
                currentReport: {
                    ...prevState.currentReport,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }


    const onYearDropChanged = async (year: string) => {
        await monthEndRepo.getAllMonthEndHdrMonthForCombox(year).then((data) => {
            let monthDropdownOption = data?.map((month) => ({
                dropdownLabel: month.toString(),
                tagLabel: month.toString(),
                value: month.toString(),
            })) ?? []
            monthDropdownOption = _.orderBy(monthDropdownOption, "dropdownLabel");

            financialReportDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    monthDropdownOptions: [
                        ...monthDropdownOption],
                },
                currentReport: {
                    ...prevState.currentReport,
                    month: monthDropdownOption.length > 0 ? monthDropdownOption[0].value : "",
                }
            }))
        })
    }


    return {
        onYearDropChanged: onYearDropChanged,
        onPrintClick: onPrintClick,
        onDownloadClick: onDownloadClick,
        onExportClick: onExportClick,
        onExportAsClick: onExportAsClick,
        onResetClick: onResetClick,
        onReportTypeChanged: onReportTypeChanged,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        initFinancialReportList: initFinancialReportList,
        onCheckboxChange: onCheckboxChange,
    }
}
