
export const FinancialReportConstant = {
    Table: {
        REPORT_TYPE: "Report",
        PRINTER: "Printer",
        FILE_LOCATION: "File Location",
        SOA: "SOA",
        BILL_TO_COMPANY: "Bill-to Company",
        CHARGE_ON_COMPANY: "Charge On Company",
        DOC_DATE_FROM: "Doc Date (From)",
        DOC_DATE_TO: "Doc Date (To)",
        GL_DATE_FROM: "GL Date (From)",
        GL_DATE_TO: "GL Date (To)",
        EXPORT_TYPE: "Export Type",
        VSL_OPERATION_DATE_FROM: "VSL Start Operation Date (From)",
        VSL_OPERATION_DATE_TO: "VSL Start Operation Date (To)",
        OPERATION_DATE_FROM: "Operation Date (From)",
        OPERATION_DATE_TO: "Operation Date (To)",
        P1_OPERATION_DATE_FROM: "Period1: VSL Start Operation (From)",
        P1_OPERATION_DATE_TO: "VSL Start Operation (To)",
        P2_OPERATION_DATE_FROM: "Period2: VSL Start Operation (From)",
        P2_OPERATION_DATE_TO: "VSL Start Operation (To)",
        ETD_DATE_FROM: "ETD (From)",
        ETD_DATE_TO: "ETD (To)",
        EFFECTIVE_DATE_FROM: "Effective Date (From)",
        EFFECTIVE_DATE_TO: "Effective Date (To)",
        YEAR: "Year",
        MONTH: "Month",
    },
    Title: {
        TITLE: "Financial Report",
        PDF: "PDF",
        CSV: "CSV",
        RTF: "RTF",
        XLS: "XLS",
    },
}
