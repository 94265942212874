export interface FinancialReportEnabledField {
    printer: boolean,
    soa: boolean,
    vslOperationDate: boolean,
    operationDate: boolean,
    p1OperationDate: boolean,
    p2OperationDate: boolean,
    etd: boolean,
    docDate: boolean,
    effectiveDate: boolean,
    chargeOnCompany: boolean,
    billToCompany: boolean,
    glDate: boolean,
    yearMonth: boolean,
}

export const DEFAULT_ENABLED_FINANCIAL_REPORT_FIELD: FinancialReportEnabledField = {
    printer: false,
    soa: false,
    vslOperationDate: false,
    operationDate: false,
    p1OperationDate: false,
    p2OperationDate: false,
    etd: false,
    docDate: false,
    effectiveDate: false,
    chargeOnCompany: false,
    billToCompany: false,
    glDate: false,
    yearMonth: false
}