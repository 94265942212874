import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { FinancialReportProvider } from "presentation/store/FinancialReport/FinancialReportProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import FinancialReportMaintenance from "presentation/view/section/FinancialReport/FinancialReportMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const FinancialReportContMain = () => {
    return <ANAInfoWrapper permission={Permission.FINANCIAL_REPORT_MAINTENANCE}>
        <MessageBarWrapper>
            <FinancialReportProvider>
                <GridStyles/>
                <FinancialReportMaintenance/>
            </FinancialReportProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default FinancialReportContMain;