import { FinancialReportEntity } from "domain/entity/FinancialReport/FinancialReportEntity";
import { FinancialReportConstant } from "presentation/constant/FinancialReport/FinancialReportConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useFinancialReportVM } from "presentation/hook/FinancialReport/useFinancialReportVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useFinancialReportTracked } from "presentation/store/FinancialReport/FinancialReportProvider";
import { financialReportCheckFieldMandatory } from "presentation/utils/report/GeneralReportCheckFieldUtils";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const FinancialReportPanel = () => {
    const FINANCIAL_REPORT_CONSTANT = FinancialReportConstant.Table;
    const [financialReportState] = useFinancialReportTracked();
    const financialReportVM = useFinancialReportVM();
    const { currentReport, reportIdTypeMap } = financialReportState;
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState(false);


    const handleReset = useCallback(() => {
        financialReportVM.onResetClick();
    }, [financialReportVM]);

    const checkFieldMandatory = useCallback((currentReport: FinancialReportEntity) => {
        const ret = financialReportCheckFieldMandatory(currentReport);
        if (ret) {
            messageBarVM.showError(ret);
            return true;
        }
        return false;
    }, [messageBarVM]);


    const handlePrint = useCallback(() => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        financialReportVM.onPrintClick(reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Report is sent to printers successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Report print failed.");
            }
        });
    }, [checkFieldMandatory, currentReport, financialReportVM, reportIdTypeMap, messageBarVM]);

    const handleExport = useCallback(() => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        financialReportVM.onExportClick(reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Export successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Export failure.");
            }
        });
    }, [checkFieldMandatory, currentReport, financialReportVM, reportIdTypeMap, messageBarVM]);

    const handleDownloadClick = useCallback((exportType: string) => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        financialReportVM.onDownloadClick(reportIdTypeMap, currentReport, exportType).then(data => {
            setIsLoading(false);
        });
    }, [checkFieldMandatory, currentReport, financialReportVM, reportIdTypeMap]);

    const handleExportClick = useCallback((exportType: string) => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        financialReportVM.onExportAsClick(reportIdTypeMap, currentReport, exportType).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Export successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Export failure.");
            }
        });
    }, [checkFieldMandatory, currentReport, financialReportVM, messageBarVM, reportIdTypeMap]);

    const memoDownloadMenu = useMemo(() => {
        return [
            {
                title: FinancialReportConstant.Title.CSV,
                disabled: false,
                command: () => {
                    handleDownloadClick(FinancialReportConstant.Title.CSV);
                }
            },
            {
                title: FinancialReportConstant.Title.XLS,
                disabled: false,
                command: () => {
                    handleDownloadClick(FinancialReportConstant.Title.XLS);
                }
            }
        ]
    }, [handleDownloadClick])

    const memoExportMenu = useMemo(() => {
        return [
            {
                title: FinancialReportConstant.Title.PDF,
                disabled: false,
                command: () => {
                    handleExportClick(FinancialReportConstant.Title.PDF);
                }
            },
            {
                title: FinancialReportConstant.Title.CSV,
                disabled: false,
                command: () => {
                    handleExportClick(FinancialReportConstant.Title.CSV);
                }
            },
            {
                title: FinancialReportConstant.Title.RTF,
                disabled: false,
                command: () => {
                    handleExportClick(FinancialReportConstant.Title.RTF);
                }
            }
        ]
    }, [handleExportClick])




    useEffect(() => {
        const refreshEnableField = async () => {
            (currentReport.reportType) && financialReportVM.onReportTypeChanged(currentReport.reportType);
        }
        refreshEnableField();
    }, [financialReportVM, currentReport.reportType])


    useEffect(() => {
        const refreshMonth = async () => {
            if ((currentReport.reportType === "Revenue By Tariff And Customer")
                && currentReport.year) {
                financialReportVM.onYearDropChanged(currentReport.year);
            }
        }
        refreshMonth();
    }, [financialReportVM, currentReport.reportType, currentReport.year])



    const memoReportType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px", marginTop: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.reportType ?? ''}
                fieldValue={currentReport.reportType}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.REPORT_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reportType'}
                maxLength={60}
                options={financialReportState.dynamicOptions.reportTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.REPORT_TYPE, financialReportState.dynamicOptions.reportTypeDropdownOptions, financialReportVM, currentReport.reportType])

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.printer ?? ''}
                fieldValue={currentReport.printer}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.PRINTER}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printer'}
                maxLength={60}
                options={financialReportState.dynamicOptions.printQueueDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.PRINTER, financialReportState.dynamicOptions.printQueueDropdownOptions, financialReportVM, currentReport.printer])

    const memoFileLocation = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.fileLocation ?? ''}
                fieldValue={currentReport.fileLocation}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.FILE_LOCATION}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fileLocation'}
                maxLength={60}
                options={financialReportState.dynamicOptions.fileLocationDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.FILE_LOCATION, financialReportState.dynamicOptions.fileLocationDropdownOptions, financialReportVM, currentReport.fileLocation])

    const memoSoa = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.soa ?? ''}
                fieldValue={currentReport?.soa}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.SOA}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'soa'}
                maxLength={60}
                options={financialReportState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.soa, FINANCIAL_REPORT_CONSTANT.SOA, financialReportState.dynamicOptions.soaDropdownOptions, financialReportVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.billToCompany ?? ''}
                fieldValue={currentReport?.billToCompany}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                options={financialReportState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.billToCompany, FINANCIAL_REPORT_CONSTANT.BILL_TO_COMPANY, financialReportState.dynamicOptions.companyCodeDropdownOptions, financialReportVM])


    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.chargeOnCompany ?? ''}
                fieldValue={currentReport?.chargeOnCompany}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                maxLength={60}
                options={financialReportState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.chargeOnCompany, FINANCIAL_REPORT_CONSTANT.CHARGE_ON_COMPANY, financialReportState.dynamicOptions.companyCodeDropdownOptions, financialReportVM])

    const memoDocDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={FINANCIAL_REPORT_CONSTANT.DOC_DATE_FROM}
                width="300px"
                date={currentReport?.docDateFrom}
                fieldName="docDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.DOC_DATE_FROM, currentReport?.docDateFrom, financialReportVM.onHeaderFieldChange])

    const memoDocDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={FINANCIAL_REPORT_CONSTANT.DOC_DATE_TO}
                width="300px"
                date={currentReport?.docDateTo}
                fieldName="docDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.DOC_DATE_TO, currentReport?.docDateTo, financialReportVM.onHeaderFieldChange])

    const memoGlDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={FINANCIAL_REPORT_CONSTANT.GL_DATE_FROM}
                width="300px"
                date={currentReport?.glDateFrom}
                fieldName="glDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.GL_DATE_FROM, currentReport?.glDateFrom, financialReportVM.onHeaderFieldChange])

    const memoGlDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={FINANCIAL_REPORT_CONSTANT.GL_DATE_TO}
                width="300px"
                date={currentReport?.glDateTo}
                fieldName="glDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.GL_DATE_TO, currentReport?.glDateTo, financialReportVM.onHeaderFieldChange])


    const memoEffectiveDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.EFFECTIVE_DATE_FROM}
                width="300px"
                optional={false}
                date={currentReport?.effectiveDateFrom}
                fieldName="effectiveDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.EFFECTIVE_DATE_FROM, currentReport?.effectiveDateFrom, financialReportVM.onHeaderFieldChange])

    const memoEffectiveDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.EFFECTIVE_DATE_TO}
                width="300px"
                optional={false}
                date={currentReport?.effectiveDateTo}
                fieldName="effectiveDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.EFFECTIVE_DATE_TO, currentReport?.effectiveDateTo, financialReportVM.onHeaderFieldChange])

    const memoP1OperationDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.P1_OPERATION_DATE_FROM}
                width="300px"
                optional={false}
                date={currentReport?.p1OperationDateFrom}
                fieldName="p1OperationDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.P1_OPERATION_DATE_FROM, currentReport?.p1OperationDateFrom, financialReportVM.onHeaderFieldChange])

    const memoP1OperationDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.P1_OPERATION_DATE_TO}
                width="300px"
                optional={false}
                date={currentReport?.p1OperationDateTo}
                fieldName="p1OperationDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.P1_OPERATION_DATE_TO, currentReport?.p1OperationDateTo, financialReportVM.onHeaderFieldChange])

    const memoP2OperationDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.P2_OPERATION_DATE_FROM}
                width="300px"
                optional={false}
                date={currentReport?.p2OperationDateFrom}
                fieldName="p2OperationDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.P2_OPERATION_DATE_FROM, currentReport?.p2OperationDateFrom, financialReportVM.onHeaderFieldChange])

    const memoP2OperationDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.P2_OPERATION_DATE_TO}
                width="300px"
                optional={false}
                date={currentReport?.p2OperationDateTo}
                fieldName="p2OperationDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.P2_OPERATION_DATE_TO, currentReport?.p2OperationDateTo, financialReportVM.onHeaderFieldChange])

    const memoOperationDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.OPERATION_DATE_FROM}
                width="300px"
                optional={false}
                date={currentReport?.operationDateFrom}
                fieldName="operationDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.OPERATION_DATE_FROM, currentReport?.operationDateFrom, financialReportVM.onHeaderFieldChange])

    const memoOperationDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.OPERATION_DATE_TO}
                width="300px"
                optional={false}
                date={currentReport?.operationDateTo}
                fieldName="operationDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.OPERATION_DATE_TO, currentReport?.operationDateTo, financialReportVM.onHeaderFieldChange])

    const memoEtdDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.ETD_DATE_FROM}
                width="300px"
                optional={false}
                date={currentReport?.etdFrom}
                fieldName="etdFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.ETD_DATE_FROM, currentReport?.etdFrom, financialReportVM.onHeaderFieldChange])

    const memoEtdDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.ETD_DATE_TO}
                width="300px"
                optional={false}
                date={currentReport?.etdTo}
                fieldName="etdTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.ETD_DATE_TO, currentReport?.etdTo, financialReportVM.onHeaderFieldChange])

    const memoVslOperationDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.VSL_OPERATION_DATE_FROM}
                width="300px"
                optional={false}
                date={currentReport?.vslOperationDateFrom}
                fieldName="vslOperationDateFrom"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.VSL_OPERATION_DATE_FROM, currentReport?.vslOperationDateFrom, financialReportVM.onHeaderFieldChange])

    const memoVslOperationDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={FINANCIAL_REPORT_CONSTANT.VSL_OPERATION_DATE_TO}
                width="300px"
                optional={false}
                date={currentReport?.vslOperationDateTo}
                fieldName="vslOperationDateTo"
                errorMessage={""}
                onDateChange={financialReportVM.onHeaderFieldChange} />
        </div>
        , [FINANCIAL_REPORT_CONSTANT.VSL_OPERATION_DATE_TO, currentReport?.vslOperationDateTo, financialReportVM.onHeaderFieldChange])

    const memoYearDrop = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.year ?? ''}
                fieldValue={currentReport?.year}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.YEAR}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'year'}
                maxLength={60}
                options={financialReportState.dynamicOptions.yearDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.year, FINANCIAL_REPORT_CONSTANT.YEAR, financialReportState.dynamicOptions.yearDropdownOptions, financialReportVM])

    const memoMonth = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.month ?? ''}
                fieldValue={currentReport?.month}
                fieldLabel={FINANCIAL_REPORT_CONSTANT.MONTH}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'month'}
                maxLength={60}
                options={financialReportState.dynamicOptions.monthDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    financialReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.month, FINANCIAL_REPORT_CONSTANT.MONTH, financialReportState.dynamicOptions.monthDropdownOptions, financialReportVM])



    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{FinancialReportConstant.Title.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper" style={{ marginRight: '5px' }}>
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CLEAR} size={"Small"} theme={"Secondary"} disabled={false} onClick={handleReset} />
                <div className="add-seperator" />
                {(currentReport.reportType !== "Revenue By Tariff And Customer") && <HPHButton label={WorkspaceConstant.Common.BUTTON_EXPORT} size={"Small"} theme={"Secondary"} disabled={false} onClick={handleExport} />}
                {(currentReport.reportType === "Revenue By Tariff And Customer") && <OverflowMenuButton buttonTheme={"Secondary"} disabled={false} buttonLabel={WorkspaceConstant.Common.BUTTON_EXPORT_AS} navigationList={memoExportMenu} />}
                <OverflowMenuButton buttonTheme={(currentReport.reportType === "Revenue By Tariff And Customer") ? "Secondary" : "Primary"} disabled={false} buttonLabel={WorkspaceConstant.Common.BUTTON_DOWNLOAD_AS} navigationList={memoDownloadMenu} />
                {(currentReport.reportType === "Revenue By Tariff And Customer") && <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={"Small"} theme={"Primary"} disabled={false} onClick={handlePrint} />}
            </StyledAction>
        </Sidebarheader>

        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoReportType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoFileLocation}
                            {financialReportState.enabledField.printer ? memoPrinter : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.docDate ? memoDocDateFrom : null}
                            {financialReportState.enabledField.docDate ? memoDocDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.glDate ? memoGlDateFrom : null}
                            {financialReportState.enabledField.glDate ? memoGlDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.soa ? memoSoa : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.vslOperationDate ? memoVslOperationDateFrom : null}
                            {financialReportState.enabledField.vslOperationDate ? memoVslOperationDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.operationDate ? memoOperationDateFrom : null}
                            {financialReportState.enabledField.operationDate ? memoOperationDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.p1OperationDate ? memoP1OperationDateFrom : null}
                            {financialReportState.enabledField.p1OperationDate ? memoP1OperationDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.p2OperationDate ? memoP2OperationDateFrom : null}
                            {financialReportState.enabledField.p2OperationDate ? memoP2OperationDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.etd ? memoEtdDateFrom : null}
                            {financialReportState.enabledField.etd ? memoEtdDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.billToCompany ? memoBillToCompany : null}
                            {financialReportState.enabledField.chargeOnCompany ? memoChargeOnCompany : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.yearMonth ? memoYearDrop : null}
                            {financialReportState.enabledField.yearMonth ? memoMonth : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {financialReportState.enabledField.effectiveDate ? memoEffectiveDateFrom : null}
                            {financialReportState.enabledField.effectiveDate ? memoEffectiveDateTo : null}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        </StyledSidebar >
    </>
}

export default memo(FinancialReportPanel);
