
import { axiosGetData } from "../axios/AxiosBasicImpl";
import reportAxiosInstance from "../axios/reportAxiosInstance";
import { MonthEndRepository } from "./MonthEndRepo";

export const MonthEndRepoImpl = (): MonthEndRepository => {



    const getAllMonthEndHdrYearForCombox = async (): Promise<number[]> => {
        const url = '/v1/searchMonthEndHdrYearFroCombox';
        return axiosGetData(reportAxiosInstance, `${url}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }
    const getAllMonthEndHdrMonthForCombox = async (year: string): Promise<number[]> => {
        const url = '/v1/searchMonthEndHdrMonthFroCombox';
        return axiosGetData(reportAxiosInstance, `${url}/${year}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getAllMonthEndHdrYearForCombox: getAllMonthEndHdrYearForCombox,
        getAllMonthEndHdrMonthForCombox: getAllMonthEndHdrMonthForCombox,
    }
}